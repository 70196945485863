<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :isDownload="true"
        @onExportData="onDownload"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>
<script>
import {mapState} from "vuex";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: 'pay_status',
          tag:"select",
          placeholder: '筛选缴费状态',
          options:[],
          label:"name",
          value:"id"
        },
        {
          prop: 'school_year',
          tag:"year",
          placeholder: '筛选缴费学年',
        },
        {
          prop: 'school_id',
          tag:"select",
          placeholder: '筛选校区',
          options:[],
          label:"school_name",
          value:"id"
        },
        {
          prop: 'grade_id',
          tag:"select",
          placeholder: '筛选年级',
          options:[],
          label:"name",
          value:"id"
        },
        {
          prop: "student_name",
          placeholder: "搜索学生姓名",
        },
        {
          prop: "customer_name",
          placeholder: "搜索家长姓名",
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        {prop: "pay_status_text", label: "缴费状态"},
        {prop: "student_no", label: "学号"},
        {prop: "student_name", label: "学生姓名"},
        {prop: "customer_name", label: "家长姓名"},
        {prop: "customer_phone", label: "家长电话"},
        {prop: "school_year", label: "缴费学年"},
        {prop: "class_name", label: "行政班"},
        {prop: "teacher_name", label: "班主任"},
        {prop: "grade", label: "年级"},
        {prop: "school", label: "校区"},
        {prop: "money_answer", label: "应付金额"},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          render(row) {
            return ["编辑"]
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getStatus()
    // this.getYears()
    this.getSchool()
    this.getClass()
    this.getData()
  },
  activated() {
    this.getStatus()
    // this.getYears()
    this.getSchool()
    this.getClass()
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    // getYears(){
    //   let str = new Date().getFullYear();
    //   this.searchConfig[1].options = [
    //     {name: str+'年', id:str},
    //     {name: str + 1 + '年', id:str + 1},
    //     {name: str + 2 +'年', id:str + 2}
    //   ]
    // },

    getStatus(){
      this.$_register('api/recruit/renew/get-param').then(res => {
        this.searchConfig[0].options = res.data.data.pay_status || []
      })
    },

    getSchool(){
      this.$_register('api/recruit/common/consulting-school').then(res => {
        this.searchConfig[2].options = res.data.data.entity_school || []
      })
    },

    getClass(){
      this.$_register('api/recruit/common/grade').then(res => {
        this.searchConfig[3].options = res.data.data || []
      })
    },

    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      this.tableData = [];
      this.$_register('api/recruit/renew/index', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, text) {
      this.$router.push('./edit?id=' + row.id + '&name=' + row.student_name)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },

    onDownload(){
      let params = {...this.search, page: this.page}
      this.$_register('api/recruit/renew/export', {params}).then(res => {
        tools.download('往届生续费', res.data)
      })
    }

  },
}
</script>
<style lang="scss" scoped>
::v-deep .t-search-block .el-input {

}

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
